import React from 'react';

const About = () => (
    <main>
        <section className="info-headline-section">
            <h2 className="text-gradient">About us</h2>
        </section>
        <section className="info-content-section">
            <div className="content-wrap">
                <div className="text-wrap">
                    <h5>Since embarking on our crypto marketing journey in 2014, we have leveraged an innovative
                        approach to navigate the world of cryptocurrency, with a particular emphasis on DeFi since
                        2017.<br/><br/>
                        Our expertise extends to growth hacking, employing creative, low-cost strategies to help
                        businesses acquire and retain customers.<br/><br/>
                        We also conduct thorough research on potential advertisers' competitors to better understand
                        the market's challenges and dynamics.<br/><br/>
                        Our guerrilla marketing stunts and growth hacking tactics have been featured on Reuters,
                        Bloomberg, MTV, and many other prominent media outlets, earning recognition for delivering
                        real, impactful results.<br/><br/>
                        Driven by a passion for the crypto space and a commitment to our clients, we understand the
                        unique challenges and opportunities within this industry. We leverage our deep expertise to
                        provide tailored marketing and growth strategies that truly resonate.<br/><br/>
                        We're not just about ideas but about making things happen, creating real value for our
                        customers through creative solutions and measurable success.<br/><br/>
                        Contact us, and let's drive the future of your projects together.</h5>
                </div>
                <div className="btn-wrap">
                    <a href="https://t.me/AlexC2013" target="_blank" rel="noreferrer" className="btn-main">
                        <span className="bg"></span>
                        <span className="text">Contact Us Now</span>
                    </a>
                </div>
            </div>
        </section>
    </main>
);

export default About;
