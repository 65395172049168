import React from 'react';

import heroBg from '../../../assets/img/hero-bg.png';

const Hero = () => (
    <section id="hero" className="hero-section">
        <div className="bg-wrap">
            <img src={heroBg} alt="Bg"/>
        </div>
        <div className="text-wrap">
            <h1 className="headline-gradient">Tailor-made Telegram<br/> ad campaigns</h1>
            <h5>Enhance your DeFi brand's reach with our all-in-one DeFi marketing suite, blending targeted Telegram ad
                campaigns, vigilant moderation, active raiding, and savvy social media management for peak community
                engagement.</h5>
        </div>
    </section>
);

export default Hero;
