import {useState, useMemo, useEffect} from 'react';
import {Routes, Route} from 'react-router-dom';
import {useLocation} from 'react-router-dom';

import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import Terms from './components/Terms';
import Policy from './components/Policy';
import Error from './components/Error';
import Footer from './components/Footer';

const App = () => {
    let location = useLocation();
    const {pathname} = location;
    const [pageScrollPosition, setPageScrollPosition] = useState(0);
    const [isHeaderActive, setIsHeaderActive] = useState(false);

    const body = document.querySelector('body');

    const isHomePage = useMemo(() => pathname === '/', [pathname])
    const isAboutPage = useMemo(() => pathname === '/about', [pathname])
    const isTermsPage = useMemo(() => pathname === '/terms', [pathname])
    const isPolicyPage = useMemo(() => pathname === '/policy', [pathname])

    useEffect(() => {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = "manual";
        }

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [pathname]);

    useEffect(() => {
        if (isHeaderActive) {
            body.classList.add('overflow-hidden');
        } else {
            body.classList.remove('overflow-hidden');
        }
    }, [isHeaderActive, body]);

    const handleScroll = () => {
        const position = window.scrollY;
        setPageScrollPosition(Math.round(position));

        Array.from(document.querySelectorAll('.animation')).forEach((ae) => {
            const {bottom, height} = ae.getBoundingClientRect();
            if (bottom - (height / 2) - window.innerHeight < 0) {
                ae.classList.add('animation-active');
            } else {
                ae.classList.remove('animation-active');
            }
        })
    };

    const appHeightHandle = () => {
        document.querySelector('body').style.setProperty('--app-height', window.innerHeight + 'px');
    }

    window.addEventListener('resize', () => {
        if (window.innerWidth < 1200) {
            appHeightHandle();
        }
    });

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {
            passive: true
        });
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    appHeightHandle();

    return (
        <>
            <Header
                isHomePage={isHomePage}
                isAboutPage={isAboutPage}
                isTermsPage={isTermsPage}
                isPolicyPage={isPolicyPage}
                pageScrollPosition={pageScrollPosition}
                isHeaderActive={isHeaderActive}
                setIsHeaderActive={setIsHeaderActive}
            />
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/about" element={<About/>}/>
                <Route path="/terms" element={<Terms/>}/>
                <Route path="/policy" element={<Policy/>}/>
                <Route path="*" element={<Error/>}/>
            </Routes>
            <Footer/>
        </>
    )
};

export default App;
