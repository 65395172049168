import React from 'react';

import adsImg from '../../../assets/img/services-ads-img.png';
import adsIcon from '../../../assets/img/services-ads-icon.svg';
import moderationImg from '../../../assets/img/services-moderation-img.png';
import moderationIcon from '../../../assets/img/services-moderation-icon.svg';
import raidingImg from '../../../assets/img/services-raiding-img.png';
import raidingIcon from '../../../assets/img/services-raiding-icon.svg';
import smmImg from '../../../assets/img/services-smm-img.png';
import smmIcon from '../../../assets/img/services-smm-icon.svg';

const Services = () => (
    <>
        <section id="services-ads" className="services-section">
            <div className="item">
                <div className="text-wrap">
                    <div className="icon-wrap animation">
                        <img src={adsIcon} alt="Icon"/>
                    </div>
                    <h3 className="headline-gradient animation">Telegram Ads</h3>
                    <h5 className="animation">DeFiMarketing.io runs dedicated Telegram ad campaigns that are tailor-made
                        for your project. Leveraging a custom-curated list of channels known for high conversion rates,
                        we ensure your ads reach the most relevant and responsive audiences. We are well-versed in the
                        intricacies of Telegram and understand the mindset of cryptocurrency enthusiasts deeply, having
                        been active in crypto since 2013 and on Telegram since 2014</h5>
                    <div className="btn-wrap animation">
                        <a href="https://t.me/AlexC2013" target="_blank" rel="noreferrer" className="btn-main">
                            <span className="bg"></span>
                            <span className="text">Contact Us</span>
                        </a>
                    </div>
                </div>
                <div className="img-wrap">
                    <img src={adsImg} alt="Ads"/>
                </div>
            </div>
        </section>
        <section id="services-moderation" className="services-section">
            <div className="item item-revert">
                <div className="img-wrap">
                    <img src={moderationImg} alt="Moderation"/>
                </div>
                <div className="text-wrap">
                    <div className="icon-wrap animation">
                        <img src={moderationIcon} alt="Icon"/>
                    </div>
                    <h3 className="headline-gradient animation">Moderation<br/> Services</h3>
                    <h5 className="animation">A dedicated 24/7 support team is essential. Potential clients expect
                        meticulous management of your chats, creating a sense of order and security. They can rest
                        assured knowing that a vigilant team is monitoring conversations around the clock, ensuring a
                        safe and responsive environment.</h5>
                    <div className="btn-wrap animation">
                        <a href="https://t.me/AlexC2013" target="_blank" rel="noreferrer" className="btn-main">
                            <span className="bg"></span>
                            <span className="text">Contact Us</span>
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <section id="services-raiding" className="services-section">
            <div className="item">
                <div className="text-wrap">
                    <div className="icon-wrap animation">
                        <img src={raidingIcon} alt="Icon"/>
                    </div>
                    <h3 className="headline-gradient animation">Raiding</h3>
                    <h5 className="animation">Raiders are the lifeblood of your chats, actively engaging and fostering
                        community interaction. They play a pivotal role in amplifying your presence on social media and
                        maintaining vibrant conversations. We provide teams of ten raiders each month, dedicated to
                        keeping your community lively and engaged.</h5>
                    <div className="btn-wrap animation">
                        <a href="https://t.me/AlexC2013" target="_blank" rel="noreferrer" className="btn-main">
                            <span className="bg"></span>
                            <span className="text">Contact Us</span>
                        </a>
                    </div>
                </div>
                <div className="img-wrap">
                    <img src={raidingImg} alt="Raiding"/>
                </div>
            </div>
        </section>
        <section id="services-smm" className="services-section">
            <div className="item item-revert">
                <div className="img-wrap">
                    <img src={smmImg} alt="SMM"/>
                </div>
                <div className="text-wrap">
                    <div className="icon-wrap animation">
                        <img src={smmIcon} alt="Icon"/>
                    </div>
                    <h3 className="headline-gradient animation">Social Media<br/> Marketing</h3>
                    <h5 className="animation">What is a project without a social presence? When customers notice a lack
                        of engagement on social media, they may perceive it as a shortfall in your marketing efforts. To
                        address this, we offer a specialized service where our seasoned social media manager will take
                        the helm of your Twitter account. Working closely with you, they will ensure that the most
                        pertinent information is strategically showcased on social media.</h5>
                    <div className="btn-wrap animation">
                        <a href="https://t.me/AlexC2013" target="_blank" rel="noreferrer" className="btn-main">
                            <span className="bg"></span>
                            <span className="text">Contact Us</span>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    </>
);

export default Services;
