import React from 'react';

// import 'swiper/css';
// import 'swiper/css/pagination';

import Hero from './Hero';
import Why from './Why';
import Services from './Services';
import Ready from './Ready';

const Home = () => (
    <main>
        <Hero/>
        <Why/>
        <Services/>
        <Ready/>
    </main>
);

export default Home;
