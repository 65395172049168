import React, {useCallback} from 'react';
import {Link} from "react-router-dom";

import logo from '../../assets/img/logo.svg';

const Header = (
    {
        isHomePage,
        isAboutPage,
        isTermsPage,
        isPolicyPage,
        pageScrollPosition,
        isHeaderActive,
        setIsHeaderActive
    }
) => {
    const onNavLinkClick = useCallback((section) => {
        setIsHeaderActive(false);

        if (section) {
            window.scrollTo({
                top: document.getElementById(section).offsetTop - window.innerHeight / 5,
                behavior: 'smooth'
            })
        }
    }, [setIsHeaderActive]);

    return (
        <header
            className={`${isHeaderActive ? 'active-header' : ''} ${pageScrollPosition > 80 ? 'scroll-header' : ''}`}>
            <div className="logo-wrap">
                <Link to="/">
                    <img src={logo} alt="Logo"/>
                </Link>
            </div>
            <div className="toggle-wrap mobile-only">
                <div className="btn-toggle" onClick={() => setIsHeaderActive(!isHeaderActive)}>
                    <span className="text">
                        <p>Menu</p>
                    </span>
                    <span className="icons">
                        <span className="icon"></span>
                        <span className="icon"></span>
                    </span>
                </div>
            </div>
            <div className="nav-wrap">
                <div className="nav-links-wrap">
                    {isHomePage ? (
                        <ul>
                            <li>
                                <Link to="/about" onClick={() => onNavLinkClick('')}><p>About Us</p></Link>
                            </li>
                            <li>
                                <Link to="/" onClick={() => onNavLinkClick('services-ads')}><p>Telegram Ads</p></Link>
                            </li>
                            <li>
                                <Link to="/" onClick={() => onNavLinkClick('services-moderation')}><p>Moderation</p>
                                </Link>
                            </li>
                            <li>
                                <Link to="/" onClick={() => onNavLinkClick('services-raiding')}><p>Raiding</p></Link>
                            </li>
                            <li>
                                <Link to="/" onClick={() => onNavLinkClick('services-smm')}><p>SMM</p></Link>
                            </li>
                        </ul>
                    ) : null}
                    {isAboutPage ? (
                        <ul>
                            <li>
                                <Link to="/" onClick={() => onNavLinkClick('')}><p>Home Page</p></Link>
                            </li>
                            <li>
                                <Link to="/terms" onClick={() => onNavLinkClick('')}><p>Terms of Service</p></Link>
                            </li>
                            <li>
                                <Link to="/policy" onClick={() => onNavLinkClick('')}><p>Privacy Policy</p></Link>
                            </li>
                        </ul>
                    ) : null}
                    {isTermsPage ? (
                        <ul>
                            <li>
                                <Link to="/" onClick={() => onNavLinkClick('')}><p>Home Page</p></Link>
                            </li>
                            <li>
                                <Link to="/about" onClick={() => onNavLinkClick('')}><p>About Us</p></Link>
                            </li>
                            <li>
                                <Link to="/policy" onClick={() => onNavLinkClick('')}><p>Privacy Policy</p></Link>
                            </li>
                        </ul>
                    ) : null}
                    {isPolicyPage ? (
                        <ul>
                            <li>
                                <Link to="/" onClick={() => onNavLinkClick('')}><p>Home Page</p></Link>
                            </li>
                            <li>
                                <Link to="/about" onClick={() => onNavLinkClick('')}><p>About Us</p></Link>
                            </li>
                            <li>
                                <Link to="/terms" onClick={() => onNavLinkClick('')}><p>Terms of Service</p></Link>
                            </li>
                        </ul>
                    ) : null}
                </div>
                <div className="action-wrap">
                    <div className="btn-wrap">
                        <a href="https://t.me/AlexC2013" target="_blank" rel="noreferrer"
                           className="btn-main btn-small btn-transparent">
                            <span className="bg"></span>
                            <span className="text">Contact Us</span>
                        </a>
                    </div>
                </div>
            </div>
        </header>
    )
};

export default Header;
