import React from 'react';

import customizedIcon from '../../../assets/img/why-customized-icon.svg';
import recordIcon from '../../../assets/img/why-record-icon.svg';
import supportIcon from '../../../assets/img/why-support-icon.svg';

const Why = () => (
    <section id="why" className="why-section">
        <div className="title-wrap animation">
            <h3 className="headline-gradient">Why choose us?</h3>
        </div>
        <div className="items-wrap">
            <div className="item">
                <div className="icon-wrap animation">
                    <img src={customizedIcon} alt="Icon"/>
                </div>
                <div className="text-wrap">
                    <h4 className="text-gradient animation">Customized<br/> Strategies</h4>
                    <p className="animation">We understand that each project is unique. That’s why we offer customized
                        marketing strategies
                        designed to meet your specific goals.</p>
                </div>
            </div>
            <div className="item">
                <div className="icon-wrap animation">
                    <img src={recordIcon} alt="Icon"/>
                </div>
                <div className="text-wrap">
                    <h4 className="text-gradient animation">Proven<br/> Track Record</h4>
                    <p className="animation">With a history of successful campaigns, you can trust that your marketing
                        efforts are in
                        competent hands.</p>
                </div>
            </div>
            <div className="item">
                <div className="icon-wrap animation">
                    <img src={supportIcon} alt="Icon"/>
                </div>
                <div className="text-wrap">
                    <h4 className="text-gradient animation">Dedicated<br/> Support</h4>
                    <p className="animation">Whether you are a novice in the world of online advertising or looking to
                        enhance your current
                        campaigns, our team is committed to providing the guidance you need.</p>
                </div>
            </div>
        </div>
    </section>
);

export default Why;
