import React from 'react';

const Terms = () => (
    <main>
        <section className="info-headline-section">
            <h2 className="text-gradient">Privacy Policy</h2>
        </section>
        <section className="info-content-section">
            <div className="content-wrap">
                <div className="text-wrap">
                    <div className="item">
                        <h4 className="text-gradient">1. Introduction</h4>
                        <h5>At DeFiMarketing.io, we respect your privacy and are committed to protecting it through our
                            compliance with this policy.</h5>
                    </div>
                    <div className="item">
                        <h4 className="text-gradient">2. Information Collection And Use</h4>
                        <h5>We only collect necessary information on the website in order to be able to present our
                            website in a attractive manner.</h5>
                    </div>
                    <div className="item">
                        <h4 className="text-gradient">3. Use of Information</h4>
                        <h5>The information we collect on or through our website may only be used in one of the
                            following ways: To personalize your experience, to improve our website, to improve customer
                            service, and to process transactions.</h5>
                    </div>
                    <div className="item">
                        <h4 className="text-gradient">4. Data Security</h4>
                        <h5>We implement a variety of security measures to maintain the safety of your personal
                            information.</h5>
                    </div>
                    <div className="item">
                        <h4 className="text-gradient">5. Changes to Our Privacy Policys</h4>
                        <h5>It is our policy to post any changes we make to our privacy policy on this page. The date
                            the privacy policy was last revised is identified at the top of the page.</h5>
                    </div>
                    <div className="item">
                        <h4 className="text-gradient">6. Contact Information</h4>
                        <h5>To ask questions or comment about this privacy policy and our privacy practices, contact us
                            at: <a href="https://t.me/AlexC2013" target="_blank" rel="noreferrer">t.me/AlexC2013</a>.
                        </h5>
                    </div>
                </div>
            </div>
        </section>
    </main>
);

export default Terms;
