import React from 'react';

const Error = () => (
    <main>
        <section className="info-headline-section">
            <h2 className="text-gradient">404</h2>
        </section>
    </main>
);

export default Error;
