import React from 'react';

import readyBg from '../../../assets/img/ready-bg.png';

const Ready = () => (
    <section id="ready" className="ready-section">
        <div className="bg-wrap">
            <img src={readyBg} alt="Bg"/>
        </div>
        <div className="text-wrap">
            <h2 className="headline-gradient animation">Ready to elevate<br/> your marketing?</h2>
            <h5 className="animation">With extensive experience in the crypto, and specifically in DeFi, we’ve honed our
                craft to mastery. We’re not just selling influence—we’re offering the distilled essence of our
                expertise. Our in-depth knowledge is at your service, ensuring your ads connect effectively with the
                most engaged and responsive audiences.</h5>
            <div className="btn-wrap">
                <a href="https://t.me/AlexC2013" target="_blank" rel="noreferrer" className="btn-main">
                    <span className="bg"></span>
                    <span className="text">Contact Us Now</span>
                </a>
            </div>
        </div>
    </section>
);

export default Ready;
