import React from 'react';

const Terms = () => (
    <main>
        <section className="info-headline-section">
            <h2 className="text-gradient">Terms of Service</h2>
        </section>
        <section className="info-content-section">
            <div className="content-wrap">
                <div className="text-wrap">
                    <div className="item">
                        <h4 className="text-gradient">1. Introduction</h4>
                        <h5>Welcome to DeFiMarketing.io. By accessing our website, you agree to these terms and
                            conditions. Please read them carefully.</h5>
                    </div>
                    <div className="item">
                        <h4 className="text-gradient">2. Use License</h4>
                        <h5>Permission is granted to temporarily download one copy of the materials on
                            DeFiMarketing.io for personal, non-commercial transitory viewing only.</h5>
                    </div>
                    <div className="item">
                        <h4 className="text-gradient">3. Disclaimer</h4>
                        <h5>The materials on DeFiMarketing.io are provided on an 'as is' basis. DeFiMarketing.io
                            makes no warranties, expressed or implied, and hereby disclaims and negates all other
                            warranties including, without limitation, implied warranties or conditions of
                            merchantability, fitness for a particular purpose, or non-infringement of intellectual
                            property or other violation of rights.</h5>
                    </div>
                    <div className="item">
                        <h4 className="text-gradient">4. Limitations</h4>
                        <h5>In no event shall DeFiMarketing.io or its suppliers be liable for any damages
                            (including, without limitation, damages for loss of data or profit, or due to business
                            interruption) arising out of the use or inability to use the materials on
                            DeFiMarketing.io, even if DeFiMarketing.io or a DeFiMarketing.io authorized
                            representative has been notified orally or in writing of the possibility of such
                            damage.</h5>
                    </div>
                    <div className="item">
                        <h4 className="text-gradient">5. Accuracy of Materials</h4>
                        <h5>The materials appearing on DeFiMarketing.io's website could include technical,
                            typographical, or photographic errors. DeFiMarketing.io does not warrant that any of the
                            materials on its website are accurate, complete or current.</h5>
                    </div>
                    <div className="item">
                        <h4 className="text-gradient">6. Links</h4>
                        <h5>DeFiMarketing.io has not reviewed all of the sites linked to its website and is not
                            responsible for the contents of any such linked site. The inclusion of any link does not
                            imply endorsement by DeFiMarketing.io of the site.</h5>
                    </div>
                    <div className="item">
                        <h4 className="text-gradient">7. Modifications</h4>
                        <h5>DeFiMarketing.io may revise these terms of service for its website at any time without
                            notice. By using this website you are agreeing to be bound by the then current version
                            of these terms of service.</h5>
                    </div>
                    <div className="item">
                        <h4 className="text-gradient">8. Governing Law</h4>
                        <h5>These terms and conditions are governed by and construed in accordance with the laws of
                            European Union and you irrevocably submit to the exclusive jurisdiction of the courts in
                            that State or location.</h5>
                    </div>
                </div>
            </div>
        </section>
    </main>
);

export default Terms;
